import styles from '../styles/Home.module.scss';
import { Box, Button, HStack, Stack } from '@chakra-ui/react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

const discordLink = 'https://discord.gg/uYDZpkd87V';
const mirrorLink =
  'https://mirror.xyz/0xcBf641a213E742f60DEBa3bF5ae4ee87cA295Bd5';
const twitterLink = 'https://twitter.com/DiscordSuperMan';

export default function Home() {
  const intl = useIntl();
  return (
    <Stack gap={4}>
      <ul className={styles.featList}>
        <li>
          <h3>
            {intl.formatMessage({
              defaultMessage: '支持自定义关键词，自动回复指定内容',
            })}
          </h3>
        </li>
        <li>
          <h3>
            {intl.formatMessage({
              defaultMessage: '支持添加多个频道多个账号，可以同时刷多个项目',
            })}
          </h3>
        </li>
        <li>
          <h3>
            {intl.formatMessage({
              defaultMessage: '智能防踢防抓，管理员抓机器人时自动暂停和回话',
            })}
          </h3>
        </li>
        <li>
          <h3>
            {intl.formatMessage({
              defaultMessage: '下载后开箱即用，操作简单无需任何代码轻松上手',
            })}
          </h3>
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: '支持一键抓取频道内最新聊天记录，轻松批量回复',
          })}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: '无需账号密码，用户数据保存在本地，安全有保障',
          })}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: '订阅期免费获得功能更新，持续获得最佳用户体验',
          })}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: '全网独家支持在 macOS 和 windows 双系统使用',
          })}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: '支持丰富的自定义设置，可实现各种多样化需求场景',
          })}
        </li>
      </ul>

      <HStack gap={2}>
        <Link href="/buy">
          <a href="/buy">
            <Button colorScheme="blue">
              {intl.formatMessage({ defaultMessage: '立即购买' })}
            </Button>
          </a>
        </Link>
        <Link href="/buy">
          <a href="/buy">
            <Button>
              {intl.formatMessage({ defaultMessage: '登录账户' })}
            </Button>
          </a>
        </Link>
      </HStack>

      <Box className={styles.screenshot}>
        <ScreenShot
          src="/assets/screenshot/home.png"
          alt={intl.formatMessage({
            defaultMessage: 'Discord聊天助手首页-全局配置',
          })}
        />
        <ScreenShot
          src="/assets/screenshot/bot-config.png"
          alt={intl.formatMessage({
            defaultMessage: 'Discord聊天助手首页-机器人配置',
          })}
        />
        <ScreenShot
          src="/assets/screenshot/log.png"
          alt={intl.formatMessage({
            defaultMessage: 'Discord聊天助手首页-机器人自动回复运行日志',
          })}
        />
        <ScreenShot
          src="/assets/screenshot/batch-reply.png"
          alt={intl.formatMessage({
            defaultMessage: 'Discord聊天助手首页-批量自动回复',
          })}
        />
      </Box>

      <Box className={styles.links}>
        <p>
          <strong>Email</strong>
          <a href="mailto:hi@discordsuperman.xyz" target="_blank">
            hi@discordsuperman.xyz
          </a>
        </p>
        <p>
          <strong>ENS</strong>
          <a
            href="https://app.ens.domains/name/discordsuperman.eth/details"
            target="_blank"
          >
            discordsuperman.eth
          </a>
        </p>
        <p>
          <strong>Twitter</strong>
          <a href={twitterLink} target="_blank">
            {twitterLink}
          </a>
        </p>
        <p>
          <strong>Discord</strong>
          <a href={discordLink} target="_blank">
            {discordLink}
          </a>
        </p>
      </Box>
    </Stack>
  );
}

function ScreenShot(props: {
  src: string;
  alt?: string;
  caption?: string;
  width?: number;
  height?: number;
}) {
  return (
    <figure className={styles.screenshotItem}>
      <img
        src={props.src}
        alt={props.alt || ''}
        width={props.width}
        height={props.height}
        loading="lazy"
      />
      <figcaption>{props.caption}</figcaption>
    </figure>
  );
}
